<template>
  <v-container fluid class="px-lg-5">
    <app-header :title="title" :breadcrumbs="breadcrumbs" />
    <v-card outlined flat class="fill-height pt-5" width="100%">
      <app-form :fields="fields" :submit-button="submitButton" disable-cancel-button
        footerClass="col-12 text-center my-5" @submitted="handleSubmit" />
    </v-card>
  </v-container>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';

export default {
  name: 'CompanyTheme',
  data() {
    return {
      breadcrumbs: [
        {
          text: this.$t('layout.mainMenu.home'),
          to: {name: 'Home'}
        },
        {
          text: this.$t('layout.mainMenu.settings'),
          to: {name: 'CompanyTheme'},
          exact: true
        },
        {
          text: this.$t('layout.mainMenu.companyTheme'),
          to: {name: 'CompanyTheme'},
          exact: true
        }
      ],
      title: {
        text: this.$t('layout.mainMenu.companyTheme'),
        icon: 'mdi-palette-outline'
      },
      submitButton: {
        label: this.$t('actions.save')
      }
    };
  },
  computed: {
    ...mapGetters(['theme', 'company']),
    fields() {
      return {
        primaryColor: {
          type: 'colorpicker',
          key: 'primaryColor',
          value: this.theme.primaryColor,
          hideCanvas: true,
          showSwatches: true,
          label: this.$t('models.theme.attributes.primaryColor'),
          classes: 'col-6 mb-2'
        },
        secondaryColor: {
          type: 'colorpicker',
          key: 'secondaryColor',
          value: this.theme.secondaryColor,
          hideCanvas: true,
          showSwatches: true,
          label: this.$t('models.theme.attributes.secondaryColor'),
          classes: 'col-6 mb-2'
        }
      };
    }
  },
  methods: {
    ...mapActions(['updateTheme']),
    handleSubmit(data) {
      this.updateTheme({companyId: this.company.id, form: data}).then(() => {
        this.$vuetify.theme.themes.light.primary = data.primary_color;
        this.$vuetify.theme.themes.dark.primary = data.primary_color;

        this.$vuetify.theme.themes.light.secondary = data.secondary_color;
        this.$vuetify.theme.themes.dark.secondary = data.secondary_color;
      });
    }
  }
};
</script>
